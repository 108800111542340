import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useBlockContext } from "../../BlockContext";

function BlockAlert() {
  const { blockData } = useBlockContext();
  // this stops the resize observer error
  // https://chat.openai.com/share/fa100126-28a7-4b08-9749-b2036db1f3ea
  const [shouldRenderAlert, setShouldRenderAlert] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldRenderAlert(true), 0);
    return () => clearTimeout(timer);
  }, []);
  const { blockMessage } = blockData;
  if (!shouldRenderAlert) return null;
  if (!blockMessage) return null;

  return (
    <Alert severity={blockMessage.severity} className="text-wrap">
      {blockMessage.message}
    </Alert>
  );
}

export default BlockAlert;
