import React from "react";

import {
  type ScraperBlockProps,
  type TScraperSettings
} from "@toolflow/shared";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import ScraperSettings from "../../settings/scraper/ScraperSettings";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

function ScraperBlockInner({ data, id }: Omit<ScraperBlockProps, "selected">) {
  const { label, settings } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  return (
    <>
      <ScraperSettings id={id} settings={settings as TScraperSettings} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default ScraperBlockInner;
