function arrayToObject(fields: string[]): { [key: string]: string } {
  const obj: { [key: string]: string } = {};

  for (const field of fields) {
    obj[field] = "";
  }

  return obj;
}

export default arrayToObject;
