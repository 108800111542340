import React from "react";
import { NodeResizeControl } from "reactflow";
import { useBlockContext } from "../../BlockContext";
import { ExpandLeftRightIcon } from "../../../../../../globalTheme/icons/icons";

const SelectedResizer = () => {
  const { selected, blockData } = useBlockContext();
  const { blockMessage } = blockData;
  if (!selected) return null;
  return (
    <NodeResizeControl
      minWidth={330}
      minHeight={!!blockMessage ? 152 : 104}
      maxHeight={!!blockMessage ? 152 : 72}
      shouldResize={(e, p) => p.direction[1] === 0}
      color="#673AB6"
      style={{
        width: 0,
        height: 0,
        border: 0,
        top: 60,
        cursor: "ew-resize"
      }}
    >
      <div className="p-l-8px">
        <ExpandLeftRightIcon color="disabled" />
      </div>
    </NodeResizeControl>
  );
};

export default SelectedResizer;
