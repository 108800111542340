import React from "react";
import { CardContent, Typography } from "@mui/material";
import CustomToolInputFieldToolbuilderProvider from "./customToolInputFields/CustomToolInputFieldToolbuilderProvider";
import { InputIcon } from "../../../../globalTheme/icons/icons";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import DrawerHeaderContainer from "./builderDrawer/components/drawerHeader/DrawerHeaderContainer";

function ToolInputDrawer() {
  return (
    <>
      <DrawerHeaderContainer
        BlockIcon={<InputIcon color={toolflowPrimaryColor} />}
        blockIconClassName="bg-color-secondary-color"
        title={<Typography variant="h8">Collect user inputs</Typography>}
      />
      <CardContent className="scrollable-content h-100-percent">
        <CustomToolInputFieldToolbuilderProvider />
      </CardContent>
    </>
  );
}

export default ToolInputDrawer;
