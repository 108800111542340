import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import InputTypeSelect from "../../../tools/components/editorToolCard/inputs/inputBuilders/InputTypeSelect";
import type { SelectChangeEvent } from "@mui/material";
import useIsUniqueField from "./useIsUniqueField";
import { TEXTFIELD } from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import InputOptionsFields from "./UpdateOptionsTextField";
import { updateInputType } from "../../../tools/components/editorToolCard/inputs/helpers/constants";

const AddInputDialog = ({
  openModal,
  setOpenModal,
  callback,
  closeCallback,
  initialName = "",
  initialType = TEXTFIELD,
  initialOptions,
  hideTypeDropdown,
  allowedFields,
  addNewFieldCondition = false
}: {
  setOpenModal: (a: boolean) => void;
  openModal: boolean;
  callback?: (s: CustomToolInputField) => void;
  closeCallback?: () => void;
  initialName?: string;
  initialType?: CustomToolInputFieldTypes;
  hideTypeDropdown?: boolean;
  allowedFields?: CustomToolInputFieldTypes[];
  initialOptions?: string[];
  addNewFieldCondition?: boolean;
}) => {
  const { state, dispatch } = useToolbuilderContext();
  const isUniqueField = useIsUniqueField();

  const getReset = () => {
    return {
      name: initialName,
      type: initialType,
      id: uuidv4(),
      options: initialOptions
    } as CustomToolInputField;
  };

  const [newField, setNewField] = useState<CustomToolInputField>(getReset());

  useEffect(() => {
    setNewField(getReset());
  }, [initialName, initialType, initialOptions]);
  //   const [options, setOptions] = useState<string[] | undefined>(undefined);

  const onAddField = (updatedToolInputFields: CustomToolInputField[]) => {
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };

  const handleCloseModal = () => {
    if (closeCallback) {
      closeCallback();
    }
    setOpenModal(false);
    setNewField(getReset());
  };

  const handleSaveNewField = () => {
    if (isUniqueField(newField.name, state.currentState.toolOutputOptions)) {
      if (!addNewFieldCondition) {
        onAddField([...state.currentState.toolInputFields, { ...newField }]);
        if (callback) {
          callback(newField);
        }
      }
      setOpenModal(false);
      setNewField(getReset());
    }
  };

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    const newF = updateInputType(
      e.target.value as CustomToolInputFieldTypes,
      newField
    );
    setNewField(newF);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle>Add user input</DialogTitle>
      <DialogContent>
        {!hideTypeDropdown && (
          <InputTypeSelect
            type={newField.type}
            margin="normal"
            onChange={handleTypeChange}
            allowedFields={allowedFields}
          />
        )}
        <TextField
          // inputRef={textFieldRef}
          fullWidth
          autoFocus
          label="Name"
          margin="normal"
          autoComplete="off"
          value={newField.name || ""}
          onChange={(e) => setNewField({ ...newField, name: e.target.value })}
        />

        <TextField
          // inputRef={textFieldRef}
          fullWidth
          label="Description"
          margin="normal"
          autoComplete="off"
          value={newField.description || ""}
          onChange={(e) =>
            setNewField({ ...newField, description: e.target.value })
          }
        />
        <InputOptionsFields newField={newField} setNewField={setNewField} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={handleSaveNewField} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInputDialog;
