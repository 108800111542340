import { Typography } from "@mui/material";
import { BlockContextProvider } from "../../../BlockContext";
import { blockExpandedComponentDict } from "../../../blocks/constants/blockConfig";
import useGetCurrentBlock from "../../../store/hooks/useGetCurrentBlock";
import { DrawerHeader } from "../drawerHeader/DrawerHeaderContainer";
import isRunnableBlock from "../../functions/isRunnableBlock";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./nodeExpandedContent.module.css";
import useBlockIcon from "../../../blocks/components/BlockIcon";
import { ArrowLeftSmallIcon } from "../../../../../../../globalTheme/icons/icons";
import { setDrawerExpandedClosed } from "../../../store/toolBuilderSlice";
import { useDispatch } from "react-redux";

const cx = parsedClassNames.bind(styles);

const useNodeExpandedContent = () => {
  const currentBlock = useGetCurrentBlock();
  const dispatch = useDispatch();
  const BlockIcon = useBlockIcon(currentBlock?.data);

  const closeExpanded = () => {
    dispatch(setDrawerExpandedClosed());
  };

  return {
    closeExpanded,
    currentBlock: currentBlock,
    BlockIcon
  };
};

const NodeExpandedContent = () => {
  const { closeExpanded, currentBlock, BlockIcon } = useNodeExpandedContent();

  if (!currentBlock || !isRunnableBlock(currentBlock)) {
    return null;
  }
  const Component = blockExpandedComponentDict[currentBlock.type];
  return (
    <BlockContextProvider
      id={currentBlock.id}
      data={currentBlock.data}
      selected={true}
    >
      <DrawerHeader
        BlockIcon={BlockIcon && <BlockIcon />}
        title={<Typography variant="h8">Test and preview</Typography>}
        blockIconClassName="icon-background"
        ActionIcon={<ArrowLeftSmallIcon size={24} />}
        closeDrawer={closeExpanded}
      />
      <div className={cx("scrollable-content", styles.height)}>
        <Component data={currentBlock.data} id={currentBlock.id} />
      </div>
    </BlockContextProvider>
  );
};

export default NodeExpandedContent;
