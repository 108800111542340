import {
  BlockTypes,
  type SerpBlockData,
  type SerpBlockProps
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import TestToolRun from "../../common/TestToolRun";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestSerpFields from "./TestSerpFields";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";

function TestSerp({ data, id }: Omit<SerpBlockProps, "selected">) {
  const {
    action,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    fields,
    userInput,
    setUserInput
  } = useTest<SerpBlockData>({
    data,
    id,
    customField: {
      name: data.settings.userQueriesFieldKey,
      id: uuidv4(),
      type: TEXTFIELD
    },
    blockType: BlockTypes.SerpBlockNode,
    fieldInput: ""
  });

  return (
    <div className="flex flex-column w-100-percent h-100-percent">
      <div className="flex-grow-1">
        <TestSerpFields fields={fields} uI={userInput} setUI={setUserInput} />
        <TestBlockOutput output={output} label={label} />
      </div>
      <DrawerBottom
        rightSlot={
          <TestToolRun
            action={action}
            text={text}
            endIcon={endIcon}
            showUpgrade={showUpgrade}
          />
        }
      />
    </div>
  );
}

export default TestSerp;
