import {
  BlockTypes,
  type DeepgramBlockData,
  type DeepgramBlockProps
} from "@toolflow/shared";
import TestDeepgramFields from "./TestDeepgramFields";
import { FILE_UPLOAD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { v4 as uuidv4 } from "uuid";
import TestToolRun from "../../common/TestToolRun";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";

const TestDeepgram = ({ data, id }: Omit<DeepgramBlockProps, "selected">) => {
  const {
    action,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    userInput,
    fields,
    setUserInput
  } = useTest<DeepgramBlockData>({
    data,
    id,
    customField: {
      name: data.settings.file,
      id: uuidv4(),
      type: FILE_UPLOAD
    },
    blockType: BlockTypes.DeepgramBlockNode,
    fieldInput: ""
  });
  return (
    <div className="flex flex-column w-100-percent h-100-percent">
      <div className="flex-grow-1">
        <TestDeepgramFields
          fields={fields}
          uI={userInput}
          setUI={setUserInput}
          file={data.settings.file}
        />
        <TestBlockOutput output={output} label={label} />
      </div>
      <DrawerBottom
        rightSlot={
          <TestToolRun
            action={action}
            text={text}
            endIcon={endIcon}
            showUpgrade={showUpgrade}
          />
        }
      />
    </div>
  );
};

export default TestDeepgram;
