import InOutNodeHandle from "../inOutNodeHandle/InOutNodeHandle";
import React from "react";
import { ArrowDownIcon } from "../../../../../../../globalTheme/icons/icons";
import { useBlockContext } from "../../../BlockContext";

const InHandle = () => {
  const { id } = useBlockContext();
  return (
    <InOutNodeHandle icon={<ArrowDownIcon />} handleType="target" id={id} />
  );
};

export default InHandle;
