import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { DrawerEnum } from "../../store/toolBuilderSlice";

const useBuilderDrawer = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);

  return {
    drawer,
    expanded: !!(
      drawer &&
      drawer.type !== DrawerEnum.toolInfo &&
      drawer.expanded
    )
  };
};

export default useBuilderDrawer;
