import useGetCurrentBlock from "../../../store/hooks/useGetCurrentBlock";
import {
  blockComponentDict,
  blockExpandedComponentDict
} from "../../../blocks/constants/blockConfig";
import { BlockContextProvider } from "../../../BlockContext";
import useBlockIcon from "../../../blocks/components/BlockIcon";
import DrawerHeaderContainer from "../drawerHeader/DrawerHeaderContainer";
import UpdateLabelTextField from "../../../UpdateLabelTextField";
import styles from "./nodeInfoContent.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import isRunnableBlock from "../../functions/isRunnableBlock";
import DrawerTestAndPreview from "../DrawerTestAndPreview";

const cx = parsedClassNames.bind(styles);

const NodeInfoContent = () => {
  const currentBlock = useGetCurrentBlock();
  const BlockIcon = useBlockIcon(currentBlock?.data);
  if (!currentBlock || !isRunnableBlock(currentBlock)) {
    return <></>;
  }
  const Component = blockComponentDict[currentBlock.type];
  const ExpandedComponent = blockExpandedComponentDict[currentBlock.type];
  return (
    <BlockContextProvider
      id={currentBlock.id}
      data={currentBlock.data}
      selected={true}
    >
      <DrawerHeaderContainer
        BlockIcon={BlockIcon && <BlockIcon />}
        title={<UpdateLabelTextField />}
        blockIconClassName="icon-background"
      />
      {ExpandedComponent && <DrawerTestAndPreview />}
      <div className={cx("scrollable-content", styles.height)}>
        <Component data={currentBlock.data} id={currentBlock.id} />
      </div>
    </BlockContextProvider>
  );
};

export default NodeInfoContent;
