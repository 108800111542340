import { ReactNode } from "react";
import { DrawerEnum } from "../../../store/toolBuilderSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../stores/store";
import NodeExpandedContent from "./NodeExpandedContent";

import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./drawerExpandedContent.module.css";

const cx = parsedClassNames.bind(styles);

const drawerExpandedComponents: Record<DrawerEnum, ReactNode> = {
  [DrawerEnum.toolInfo]: null,
  [DrawerEnum.inputs]: null,
  [DrawerEnum.outputs]: null,
  [DrawerEnum.node]: <NodeExpandedContent />
  // Add other drawer types here
};

const DrawerExpandedContent = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  return drawer && drawer.type !== DrawerEnum.toolInfo && drawer.expanded ? (
    <div className={cx("w-100-percent", styles.border)}>
      {drawerExpandedComponents[drawer.type]}
    </div>
  ) : null;
};

export default DrawerExpandedContent;
