import React from "react";
import { CardContent, Typography } from "@mui/material";
import CustomToolOutputFields from "./customToolOutputFields/CustomToolOutputFields";
import { OutputIcon } from "../../../../globalTheme/icons/icons";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import DrawerHeaderContainer from "./builderDrawer/components/drawerHeader/DrawerHeaderContainer";

function ToolOutputDrawer() {
  return (
    <>
      <DrawerHeaderContainer
        BlockIcon={<OutputIcon color={toolflowPrimaryColor} />}
        blockIconClassName="bg-color-secondary-color"
        title={<Typography variant="h8">Choose ouputs</Typography>}
      />
      <CardContent className="scrollable-content h-100-percent">
        <CustomToolOutputFields />
      </CardContent>
    </>
  );
}

export default ToolOutputDrawer;
