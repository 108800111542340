import React, { ReactNode } from "react";
import { Box, Divider, IconButton } from "@mui/material";

import styles from "./drawerHeader.module.css";
import { CloseIcon } from "../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import useCloseDrawer from "../../../store/hooks/useCloseDrawer";

const cx = parsedClassNames.bind(styles);

interface DrawerHeaderContainerProps {
  className?: string;
  BlockIcon: ReactNode;
  title: ReactNode;
  blockIconClassName?: string;
  ActionIcon?: ReactNode;
}

export function DrawerHeader({
  BlockIcon,
  title,
  closeDrawer,
  className,
  blockIconClassName,
  ActionIcon = <CloseIcon size={24} />
}: DrawerHeaderContainerProps & { closeDrawer: () => void }) {
  return (
    <>
      <Box
        className={cx(
          "flex align-i-center justify-space-between p-16px",
          className
        )}
      >
        <div
          className={cx(
            "align-i-center flex w-100-percent",
            styles["max-width"]
          )}
        >
          <div className={cx(styles["icon-with-circle"], blockIconClassName)}>
            {BlockIcon}
          </div>
          <div className="m-l-8px w-100-percent">{title}</div>
        </div>
        <div className="flex align-i-center m-l-8px">
          <IconButton onClick={closeDrawer}>{ActionIcon}</IconButton>
        </div>
      </Box>
      <Divider />
    </>
  );
}

function DrawerHeaderContainer(props: DrawerHeaderContainerProps) {
  const closeDrawer = useCloseDrawer();

  return <DrawerHeader {...props} closeDrawer={closeDrawer} />;
}

export default DrawerHeaderContainer;
