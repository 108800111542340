import type {
  CustomToolOutputFieldTypes,
  UserInputResponseWithHandledError
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import { COPYABLE_LARGE_TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/outputConstants";
import OutputField from "../../OutputField";

function TestBlockOutput({
  output,
  label,
  type = COPYABLE_LARGE_TEXTFIELD
}: {
  output: UserInputResponseWithHandledError | null;
  label: string;
  type?: CustomToolOutputFieldTypes;
}) {
  return (
    <AccordionWrapper
      elevation={0}
      title="Test Output"
      startsExpanded={!!output}
    >
      {output && (
        <OutputField
          toolOutput={output ? { [label]: output } : null}
          toolOutputField={{
            name: label,
            type
          }}
        />
      )}
    </AccordionWrapper>
  );
}

export default TestBlockOutput;
