import React from "react";
import { type UserInputDictType } from "@toolflow/shared";
import { TextField } from "@mui/material";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../globalTheme/muiUtils/appTheme";

function TestFields({
  fields,
  uI,
  setUI,
  filledOutPrompt
}: {
  fields: string[];
  uI: UserInputDictType;
  setUI: React.Dispatch<React.SetStateAction<UserInputDictType>>;
  filledOutPrompt: string;
}) {
  const handleUpdate = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUI({ ...uI, [field]: e.target.value });
  };

  return (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      <div>
        <div className="w-100-percent flex flex-column">
          {fields.map((field, idx) => (
            // <TipTapTextFieldContainer
            //   key={idx}
            //   disableInputs
            //   label={field}
            //   initialValue={uI[field] as string | JSONContent | undefined}
            //   onChange={handleTipTapChange(field)}
            // />
            <TextField
              name={field}
              key={idx}
              label={field}
              value={uI[field]}
              onChange={(e) => handleUpdate(field, e)}
              fullWidth
              multiline
              margin="normal"
              maxRows={MAX_ROWS_LARGE_TEXTFIELD}
            />
          ))}

          {/* <GenericRichText text={filledOutPrompt} /> */}
          <TextField
            label="Prompt to test"
            margin="normal"
            value={filledOutPrompt}
            disabled
            fullWidth
            multiline
            maxRows={MAX_ROWS_LARGE_TEXTFIELD}
            helperText={`${filledOutPrompt.length} characters`}
          />
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default TestFields;
