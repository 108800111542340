import React from "react";
import useFlow from "./hooks/useFlow";
import Reactflow, { type Edge as ReactflowEdge } from "reactflow";

const Flow: React.FC = () => {
  const {
    onConnect,
    onNodesChange,
    onEdgesChange,
    onDragOver,
    onDrop,
    onNodeClick,
    onPaneClick,
    proOptions,
    setDragParams,
    nodes,
    edges,
    reactFlowWrapper,
    defaultViewport,
    nodeTypes,
    edgeTypes,
    markerInfo
  } = useFlow();
  return (
    <div style={{ flexGrow: 1 }} ref={reactFlowWrapper}>
      <Reactflow
        nodes={nodes}
        edges={edges as unknown as ReactflowEdge[]}
        minZoom={0.1}
        maxZoom={0.75}
        defaultViewport={defaultViewport}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        proOptions={proOptions}
        defaultEdgeOptions={markerInfo}
        onNodeClick={onNodeClick}
        onPaneClick={onPaneClick}
        onConnectStart={(e, params) => setDragParams(params)}
        onConnectEnd={() => setDragParams(null)}
        deleteKeyCode={null}
        nodesFocusable={false} // this is so that when menu pops on prompt editor, you can't move / delete nodes. Check deleteKeyCode=null for other options
        // fitView
      />
    </div>
  );
};

export default Flow;
