import { ReactNode } from "react";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./drawerBottom.module.css";

const cx = parsedClassNames.bind(styles);

function DrawerBottom({
  leftSlot = <div />,
  rightSlot = <div />
}: {
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
}) {
  return (
    <div
      className={cx(
        "flex p-16px justify-space-between align-i-center",
        styles.footer
      )}
    >
      {leftSlot}
      {rightSlot}
    </div>
  );
}

export default DrawerBottom;
