import React from "react";
import { Typography, Paper } from "@mui/material";
import { useBlockContext } from "../../BlockContext";
import BlockAlert from "./BlockAlert";
import DeleteNodeButton from "./DeleteNodeButton";
import useBlockIcon from "./BlockIcon";
import useSetBlockMessage from "../hooks/useSetBlockMessage";

function BlockPaperHeader() {
  const { blockData: data } = useBlockContext();
  const BlockIcon = useBlockIcon(data);

  const { label } = data;

  useSetBlockMessage();

  return (
    <Paper>
      <div className="flex align-i-center justify-space-between p-16px min-w-300px">
        <div
          className="align-i-center flex w-100-percent"
          style={{ maxWidth: "88%" }}
        >
          {BlockIcon && <BlockIcon />}
          <Typography variant="h6" className="m-l-16px" noWrap>
            {label}
          </Typography>
        </div>
        <DeleteNodeButton />
      </div>
      <BlockAlert />
    </Paper>
  );
}

export default BlockPaperHeader;
