import { useEffect } from "react";
import { useBlockContext } from "../../BlockContext";
import useUpdateField from "../../useUpdateField";
import setBlockMessage from "../../setBlockMessage";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { type Edge } from "reactflow";
import useGetCurrentBlock from "../../store/hooks/useGetCurrentBlock";
import getDependencyArray from "../functions/getDependencyArray";

const useSetBlockMessage = () => {
  const { blockData: data, id } = useBlockContext();
  const { state } = useToolbuilderContext();
  const { edges } = state.currentState;

  const updateField = useUpdateField(id);
  const currentBlock = useGetCurrentBlock();
  /* tslint:disable-next-line */
  const dependencyArray = getDependencyArray(data, edges as Edge[]);

  useEffect(() => {
    const newBlockMessage = setBlockMessage(currentBlock, edges);
    if (newBlockMessage !== data.blockMessage) {
      updateField(newBlockMessage, "blockMessage");
    }
  }, dependencyArray);
};

export default useSetBlockMessage;
