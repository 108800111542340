import {
  BlockTypes,
  type PromptBlockData,
  type PromptBlockProps
} from "@toolflow/shared";
import TestFields from "./TestFields";
import TestToolRun from "../../common/TestToolRun";
import { useMemo } from "react";
import { getPromptWithUserInput } from "../../common/utils";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestBlockOutput from "../components/TestBlockOutput";
import {
  COPYABLE_IMAGE,
  COPYABLE_LARGE_TEXTFIELD
} from "../../../../../tools/components/editorToolCard/inputs/helpers/outputConstants";
import useTest from "../hooks/useTest";

const TestPrompt = ({ data, id }: Omit<PromptBlockProps, "selected">) => {
  const { prompt, type } = data;

  const {
    action,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    fields,
    userInput,
    setUserInput
  } = useTest<PromptBlockData>({
    data,
    id,
    blockType: BlockTypes.PromptBlockNode,
    fieldInput: prompt
  });

  const filledOutPrompt = useMemo(
    () => getPromptWithUserInput(prompt, userInput),
    [prompt, userInput]
  );

  return (
    <div className="flex flex-column w-100-percent h-100-percent">
      <div className="flex-grow-1">
        <TestFields
          fields={fields}
          uI={userInput}
          setUI={setUserInput}
          filledOutPrompt={filledOutPrompt}
        />
        <TestBlockOutput
          output={output}
          label={label}
          type={type === "Dall-E2" ? COPYABLE_IMAGE : COPYABLE_LARGE_TEXTFIELD}
        />
      </div>
      <DrawerBottom
        rightSlot={
          <TestToolRun
            action={action}
            text={text}
            endIcon={endIcon}
            showUpgrade={showUpgrade}
          />
        }
      />
    </div>
  );
};

export default TestPrompt;
