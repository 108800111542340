import { IconButton } from "@mui/material";
import { useBlockContext } from "../../BlockContext";
import React from "react";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { DeleteIcon } from "../../../../../../globalTheme/icons/icons";

function DeleteNodeButton() {
  const { id } = useBlockContext();
  const { reactflowUtility } = useToolbuilderContext();
  const { onDeleteNode } = reactflowUtility;
  const deleteNode = () => {
    onDeleteNode(id);
  };
  return (
    <IconButton onClick={deleteNode}>
      <DeleteIcon />
    </IconButton>
  );
}

export default DeleteNodeButton;
