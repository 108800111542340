import type { CustomToolInputField } from "@toolflow/shared";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { v4 as uuidv4 } from "uuid";

function transformList(
  input: string[],
  optionalField?: CustomToolInputField
): CustomToolInputField[] {
  let o: CustomToolInputField[] = input.map((name) => ({
    name,
    id: uuidv4(),
    type: TEXTFIELD
  }));
  if (optionalField) {
    o = o.concat([optionalField]);
  }
  return o;
}

export default transformList;
