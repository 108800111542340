import type { BlockTypes, TBlock } from "@toolflow/shared";

const isRunnableBlock = (
  block: TBlock
): block is TBlock & { type: BlockTypes } => {
  const nonRunnableTypes = [
    "inputBlockNode",
    "outputBlockNode",
    "parentBlockNode"
  ];
  return !nonRunnableTypes.includes(block.type);
};

export default isRunnableBlock;
