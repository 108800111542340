import React from "react";
import RemixWrapperIconWithTheme from "../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { Avatar } from "@mui/material";

function DraggableBlock({
  onDragStart,
  icon
}: {
  onDragStart?: React.DragEventHandler<HTMLDivElement>;
  icon: React.ElementType;
}) {
  return (
    <Avatar
      onDragStart={onDragStart}
      sx={{
        bgcolor: "white",
        color: "black",
        width: "32px",
        height: "32px",
        borderRadius: "8px"
      }}
      draggable
      className="cursor-grab flex align-i-center justify-center"
    >
      <RemixWrapperIconWithTheme Icon={icon} size={20} />
    </Avatar>
  );
}
export default DraggableBlock;
