import {
  secondaryClosedMixin,
  secondaryOpenedMixin
} from "./builderDrawerStyles";
import { Drawer, styled } from "@mui/material";
import DrawerContent from "./components/drawerContent/DrawerContent";
import {
  EXPANED_DRAWER_WIDTH,
  LARGE_DRAWER_WIDTH
} from "../../../../../utilities/constants/widths";
import useBuilderDrawer from "./hooks/useBuilderDrawer";
import DrawerExpandedContent from "./components/drawerContent/DrawerExpandedContent";
import { lightButtonBorder } from "../../../../../globalTheme/muiUtils/appTheme";

const StyledBuilderDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "height" && prop !== "expanded"
})<{ open: boolean; height: number; expanded: boolean }>(
  ({ theme, open, height, expanded }) => ({
    flexShrink: 0,
    boxSizing: "border-box",
    height: `calc(${height}px - 32px)`,

    "& .MuiDrawer-root": {
      position: "absolute"
    },
    "& .MuiPaper-root.MuiDrawer-paper": {
      overflow: "hidden",
      height: `calc(${height}px - 32px)`,
      position: "absolute",
      marginRight: "8px",
      marginTop: "8px",
      padding: "0px",
      right: "0px",
      top: "0px",
      background: "#FFF",
      borderRadius: "16px",
      border: open ? `1px solid ${lightButtonBorder}` : "0px"
    },
    ...(open && {
      ...secondaryOpenedMixin(
        theme,
        open,
        expanded ? EXPANED_DRAWER_WIDTH : LARGE_DRAWER_WIDTH
      ),

      boxShadow: "0px 6px 8px 0px rgba(101, 91, 71, 0.06)",
      "& .MuiDrawer-paper": secondaryOpenedMixin(
        theme,
        open,
        expanded ? EXPANED_DRAWER_WIDTH : LARGE_DRAWER_WIDTH
      )
    }),
    ...(!open && {
      ...secondaryClosedMixin(theme, !!open),
      border: "0px",

      boxShadow: "none",
      "& .MuiDrawer-paper": {
        ...secondaryClosedMixin(theme, !!open),
        borderLeft: "0px"
      }
    })
  })
);

function BuilderDrawer({ height }: { height: number }) {
  const { drawer, expanded } = useBuilderDrawer();
  return (
    <StyledBuilderDrawer
      open={!!drawer}
      height={height}
      expanded={expanded}
      anchor="right"
      variant="permanent"
    >
      <div className="flex w-100-percent h-100-percent">
        <div className="flex-column w-100-percent h-100-percent">
          <DrawerContent />
        </div>
        <DrawerExpandedContent />
      </div>
    </StyledBuilderDrawer>
  );
}

export default BuilderDrawer;
