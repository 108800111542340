import { AbortIcon } from "../../../../../../globalTheme/icons/icons";
import { useWebsocketContext } from "../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";

const useRunButtonBase = ({
  send,
  abort,
  loading
}: {
  send: () => void;
  abort: () => void;
  loading?: boolean;
}) => {
  const { showReconnectButton, reconnect } = useWebsocketContext();

  if (showReconnectButton) {
    return {
      action: reconnect,
      text: "Reconnect"
    };
  }

  if (loading) {
    return {
      action: abort,
      endIcon: <AbortIcon />,
      text: "Generating"
    };
  }

  return {
    action: send,
    text: "Run"
  };
};

export default useRunButtonBase;
