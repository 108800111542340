import React from "react";
import useWorkspaceInputs from "../../../../hooks/useWorkspaceInputs";
import Dot from "./Dot";
import styles from "./dots.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function Dots() {
  const inputs = useWorkspaceInputs();
  return (
    <div
      className={cx(
        styles["dots-width"],
        "flex h-100-percent max-h-100-percent scrollable-content justify-center"
      )}
    >
      <div
        className={cx(
          styles["dots-container"],
          "flex align-i-center flex-column justify-center"
        )}
      >
        {inputs.map((input) => {
          return <Dot key={input.id} assetId={input.id} />;
        })}
      </div>
    </div>
  );
}

export default Dots;
