import {
  type BlockDataTypes,
  BlockTypes,
  type PromptBlockData,
  type ScraperBlockData,
  type SerpBlockData,
  type DeepgramBlockData,
  type TBlock,
  Block,
  type BlockDataTypesWithDefault
} from "@toolflow/shared";
import {
  dallEPromptInitialState,
  deepgramTranscribePromptInitialState,
  promptInitialState,
  scraperSettingsInitialState,
  serpSettingsInitialState
} from "../../context/initialStates";
import {
  ConstantIcon,
  CrawlerIcon,
  ImageGenerationIcon,
  LogicIcon,
  SearchIcon,
  SpeechToTextFillIcon,
  TextGenerationIcon,
  ToolIcon
} from "../../../../../../globalTheme/icons/icons";
import PromptBlockInner from "../promptBlocks/PromptBlockInner";
import SerpBlockInner from "../serpBlock/SerpBlockInner";
import ScraperBlockInner from "../scraperBlock/ScraperBlockInner";
import DeepgramBlockInner from "../deepgram/DeepgramBlockInner";
import ToolWithinToolBlockInner from "../toolWithinTool/ToolWithinToolBlockInner";
import LogicInner from "../logic/LogicInner";
import ConstantBlockInner from "../constantBlock/ConstantBlockInner";
import React from "react";
import TestPrompt from "../promptBlocks/TestPrompt";
import TestDeepgram from "../deepgram/TestDeepgram";
import TestScraper from "../scraperBlock/TestScraper";
import TestSerp from "../serpBlock/TestSerp";

type Item = Omit<TBlock, "id" | "position" | "data" | "parentNode"> & {
  data: BlockDataTypes;
};

export const blockConfig: Record<
  Block,
  {
    draggableItem: Item;
    icon: React.ElementType;
    drawerComponent: React.ElementType;
    expandedComponent: React.ElementType | null;
  }
> = {
  [Block.logic]: {
    icon: LogicIcon,
    drawerComponent: LogicInner,
    expandedComponent: null,
    draggableItem: {
      type: BlockTypes.LogicBlockNode,
      data: {
        label: "Logic",
        type: Block.logic,
        logicArray: []
      }
    }
  },
  [Block.constant]: {
    icon: ConstantIcon,
    drawerComponent: ConstantBlockInner,
    expandedComponent: null,
    draggableItem: {
      type: BlockTypes.ConstantBlockNode,
      data: {
        label: "Constant",
        type: Block.constant,
        constant: ""
      }
    }
  },
  [Block.textGeneration]: {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    icon: TextGenerationIcon,
    draggableItem: {
      type: BlockTypes.PromptBlockNode,
      data: {
        type: "ChatGPT",
        label: "Text Output Name",
        ...promptInitialState
      } as PromptBlockData
    }
  },
  [Block.imageGeneration]: {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    icon: ImageGenerationIcon,
    draggableItem: {
      type: BlockTypes.PromptBlockNode,
      data: {
        type: "Dall-E2",
        label: "Image Output Name",
        ...dallEPromptInitialState
      } as PromptBlockData
    }
  },
  [Block.speechToText]: {
    icon: SpeechToTextFillIcon,
    drawerComponent: DeepgramBlockInner,
    expandedComponent: TestDeepgram,
    draggableItem: {
      type: BlockTypes.DeepgramBlockNode,
      data: {
        ...deepgramTranscribePromptInitialState,
        type: "DeepgramTranscribe",
        label: "Deepgram transcript"
      } as DeepgramBlockData
    }
  },
  [Block.scraper]: {
    icon: CrawlerIcon,
    expandedComponent: TestScraper,
    drawerComponent: ScraperBlockInner,
    draggableItem: {
      type: BlockTypes.ScraperBlockNode,
      data: {
        ...scraperSettingsInitialState,
        type: "WebsiteContentCrawler",
        label: "Web Scraper"
      } as ScraperBlockData
    }
  },
  [Block.serp]: {
    icon: SearchIcon,
    drawerComponent: SerpBlockInner,
    expandedComponent: TestSerp,
    draggableItem: {
      type: BlockTypes.SerpBlockNode,
      data: {
        ...serpSettingsInitialState,
        type: "serp",
        label: "Google Search Results"
      } as SerpBlockData
    }
  },
  [Block.embedded]: {
    icon: ToolIcon,
    drawerComponent: ToolWithinToolBlockInner,
    expandedComponent: null,
    draggableItem: {
      type: BlockTypes.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: "toolWithinTool",
        tool: null,
        inputMap: null
      }
    }
  }
};

export const blockHeaderIconDict: Record<
  BlockDataTypesWithDefault["type"],
  React.ElementType
> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.data.type,
      value.icon
    ])
  ),
  Anthropic: TextGenerationIcon, // we need to fix this -> the promptblock, anthropic, chatgpt, dall-e should be structured better
  default: TextGenerationIcon
} as Record<BlockDataTypesWithDefault["type"], React.ElementType>;

export const blockComponentDict: Record<BlockTypes, React.ElementType> = {
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(blockConfig).map(([_, value]) => [
      value.draggableItem.type,
      value.drawerComponent
    ])
  )
} as Record<BlockTypes, React.ElementType>;

export const blockExpandedComponentDict: Record<BlockTypes, React.ElementType> =
  {
    ...Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(blockConfig).map(([_, value]) => [
        value.draggableItem.type,
        value.expandedComponent
      ])
    )
  } as Record<BlockTypes, React.ElementType>;
