import React from "react";
import { CardContent, Container, Typography } from "@mui/material";
import { ToolIcon } from "../../../globalTheme/icons/icons";
import { DrawerHeader } from "../../pages/builder/toolBuilder/builderDrawer/components/drawerHeader/DrawerHeaderContainer";

export function MoreInfoCard({
  name,
  children,
  closeDrawer
}: Readonly<{
  name: string;
  children?: React.ReactNode;
  closeDrawer: () => void;
}>) {
  return (
    <>
      <DrawerHeader
        title={<Typography variant="h8">{name}</Typography>}
        BlockIcon={<ToolIcon />}
        closeDrawer={closeDrawer}
      />
      <Container maxWidth={"md"} disableGutters>
        <CardContent className="scrollable-content h-100-percent">
          {children}
        </CardContent>
      </Container>
    </>
  );
}
