import mongoose from "mongoose";
import { Image as OpenAIImage } from "openai/resources";
import type { Viewport } from "reactflow";
import {
  CustomToolInputField,
  ValidatedInputTypes
} from "./inputs/customToolInputFields";
import {
  AvailableFieldsType,
  CustomEdge,
  PopulatedToolVersionResponseFE,
  TBlock,
  TToolVersion,
  ToolVersionDict
} from "./models";
import { LeanFileDocument } from "./models/FileReferenceDocument";
import {
  ExternalToolFE,
  TCategory,
  TUseCase,
  VisibilityTypes
} from "./models/ToolDocument";
import { SanitizedUserType } from "./models/UserDocument";

/* These are types that should be updated */
export type $TSFixMe = any; // eslint-disable-line @typescript-eslint/no-explicit-any

/* Sometimes types should allow for "any", use this type in that situation */
export type $TSAllowedAny = any; // eslint-disable-line @typescript-eslint/no-explicit-any
export type ButtonVariantTypes = "text" | "outlined" | "contained";
export type ButtonSizeTypes = "small" | "medium" | "large";
export type TextFieldSizeTypes = "small" | "medium";

export type UsageType = {
  prompt_tokens: number;
  completion_tokens?: number;
  total_tokens: number;
};

export type RolesType = {
  [key: string]: {
    sessionRole?: string;
    initialPrompt?: string;
  };
};

export type Image = OpenAIImage;
export type DallEOutputType = OpenAIImage[];
export type TAnthropicOutputType = string;

// Define HandledChatStackError
export type HandledChatStackError = {
  type: "error";
  data: { name: string; message: string; stack?: string; status?: number };
};

export type FileDataType = string[][];

export type FileBase = {
  fileType: string;
  fileName: string;
};

export type FileData = {
  data: FileDataType;
  meta: { mapper: { [key: string]: string } };
} & FileBase;

export type AudioData = {
  data: string;
  meta: object;
} & FileBase;

export type TCopyableLargeTextField = "copyableLargeTextField";
export type TCopyableImage = "copyableImage";

export type CustomToolOutputFieldTypes =
  | TCopyableLargeTextField
  | TCopyableImage;

export type OneToTen = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type DallE2SizeType = "256x256" | "512x512" | "1024x1024";
export type DallE3SizeType = "1024x1024" | "1792x1024" | "1024x1792";
export type DalleSizeType = DallE2SizeType & DallE3SizeType;
export type DallE2OptimizationsType = {
  n: OneToTen;
  model: DallE2Model;
  size: DallE2SizeType;
  quality: undefined;
  style: undefined;
};

export type DallE3QualityType = "standard" | "hd";
export type DallE3StyleType = "vivid" | "natural";

export type DallE3OptimizationsType = {
  n: 1;
  model: DallE3Model;
  quality: DallE3QualityType;
  size: DallE3SizeType;
  style: DallE3StyleType;
};

export type DallEOptimizationsType =
  | DallE2OptimizationsType
  | DallE3OptimizationsType;

export type TDeepgramModels =
  | "nova-2"
  | "nova"
  | "enhanced"
  | "base"
  | "whisper-medium";

export type TCrawlerActors = "web-content-crawler";
export type TTimestampByParagraph = "paragraph";
export type TTimestampBySentence = "sentence";
export type TTimestampByEntity = TTimestampByParagraph | TTimestampBySentence;

export type TDeepgramSettings = {
  smartFormat: boolean;
  model: TDeepgramModels;
  language: string;
  file: string;
  diarize: boolean;
  addTimestamps: boolean;
  addSummary: boolean;
  isFileDictation: boolean;
  convertMeasurementsValuesToAbbreviations: boolean;
  keywords: string[];
  userKeywordsFieldKey: string;
  wordsToReplace: TWordPairsDict;
  userWordsToReplaceFieldKey: string;
  timeStampByEntity: TTimestampByEntity;
};
export type TPreferredScraperResult = "html" | "text" | "markdown";
export type TScraperSettings = {
  inputType: string;
  urlFieldInputKey: string;
  preferredScraperResultType: TPreferredScraperResult;
};

export type TSerpBlockAllowedOptionsValues =
  | "peopleAlsoAsk"
  | "relatedQueries"
  | "organicResults"
  | "paidResults"
  | "resultOptions";

export type TSerpOutputAllowedValuesDict = Record<
  TSerpBlockAllowedOptionsValues,
  boolean | TSerpResultOptions
>;

export type TSerpSiteLink = {
  url: string;
  title: string;
};

export type TSerpPeopleAlsoAsk = {
  question: string;
  answer: string;
  title: string;
  url: string;
};

export type TSerpRelatedQuery = {
  title: string;
  url: string;
};

export type TSerpResultOptions = {
  title: boolean;
  url: boolean;
  displayedUrl: boolean;
  description: boolean;
  emphasizedKeywords: boolean;
  siteLinks: boolean;
};

export type TSerpAllowedOptions = {
  peopleAlsoAsk: boolean;
  relatedQueries: boolean;
  organicResults: boolean;
  paidResults: boolean;
  resultOptions: TSerpResultOptions;
};
export type TEmphasizedKeyword = string;
export type TSerpSettings = {
  inputType: string;
  countryCode: string;
  languageCode: string;
  maxPagesPerQuery: number;
  resultsPerPage: number;
  queries: string | string[];
  userQueriesFieldKey: string;
  allowedOutputs: TSerpOutputAllowedValuesDict;
};

export type MapperObject = {
  [key: string]: string;
};

export type TProductionEnvironment = "production";
export type TStagingEnvironment = "staging";

export type TWorkspace = "workspace";
export type TFrequentlyUsed = "frequently-used";
export type TCreatedByUser = "created-by-user";
export type TStarred = "starred";
export type TTool = "tool";
export type TWorkflow = "workflow";
export type TProfileTabType = TWorkspace | TTool | TWorkflow;
export type THomeTabType = TWorkspace | TTool;
export type THomeEntityBlockType = TFrequentlyUsed | TStarred | TCreatedByUser;
export type TCopyableType = TWorkspace | TTool;

export type CopyTypes =
  | "workspace"
  | "workflow"
  | "tool"
  | "profile"
  | "toolVersionResponse";

export type ValidatedInput = {
  name: string;
  type: ValidatedInputTypes;
  options?: string[];
};

export type CustomToolOutputFieldPartial = {
  name: string;
  type: CustomToolOutputFieldTypes;
  options?: string[];
  id?: string;
};

export type CustomToolOutputField = Omit<CustomToolOutputFieldPartial, "id"> & {
  id: string;
};

export type TEstimatedAudioLengthInSeconds = 3600;
export type TMaximumAvailableToolRunsForFreeTier = 5;

export type TAfterRunCostBlockTypes = ChatGPTBlockData | TAnthropicBlockData;
export type BlockDataTypeMap = {
  promptBlockNode: PromptBlockData;
  deepgramBlockNode: DeepgramBlockData;
  scraperBlockNode: ScraperBlockData;
  toolWithinToolBlockNode: NotSavedToolWithinToolBlockData;
  logicBlockNode: LogicBlockData;
  constantBlockNode: ConstantBlockData;
  serpBlockNode: SerpBlockData;
};

export type BlockDataTypes = BlockDataTypeMap[keyof BlockDataTypeMap];

export type BlockInnerTypes = Extract<
  BlockDataTypes,
  { type: unknown }
>["type"];

export type BlockDataTypesWithDefault = BlockDataTypes | DefaultBlockData;

export type DefaultBlockData = {
  type: "default";
} & BlockDataBase;

export type AllBlockDataType = BlockDataTypes | BlockDataBase;

export type PromptData = {
  prompt: string;
  llmModel: OpenAIModels;
  temperature: number;
  save?: boolean;
  toolInputFields?: CustomToolInputField[];
  toolOutputFields?: CustomToolOutputField[];
  description?: string;
};

export type TTagObject = {
  categories?: TCategory[];
  useCases?: TUseCase[];
  blocks?: TBlocksTagsLabels[];
};

export type UpdateToolDataToSend = {
  main?: string;
  name?: string;
  visibility?: VisibilityTypes;
  about?: string;
  tag?: TTagObject;
};

export type BlockDuration = {
  createdAt: number;
  updatedAt: number;
  duration: string;
};

export type BlockDurationDict = { [key: string]: BlockDuration };

export type RunBlockOptimizations = OptimizationsType | DallEOptimizationsType;

export type RunBlockToolData = {
  userInput: UserInputDictType;
  blockType: BlockTypes;
  toolInputFields: CustomToolInputField[];
  blockData:
    | PromptBlockData
    | DeepgramBlockData
    | ScraperBlockData
    | SerpBlockData;
  componentId: string; // we need the componentId to appropriately abort the stream
  trackingMetrics: {
    toolId: string;
    toolVersionId: string;
    blockId: string;
  };
};

export type TDallEBlockType = "Dall-E2"; // this is a misnomer, this is for all Dall-E types, we may want to migrate the names to "Dall-E" in the future
export type TChatGPTBlockType = "ChatGPT";
export type TAnthropicBlockType = "Anthropic";
export type TTextGenerationBlockType = TChatGPTBlockType | TAnthropicBlockType;

export enum PromptTypes {
  DallE = "Dall-E2",
  ChatGPT = "ChatGPT",
  Anthropic = "Anthropic"
}

export type PromptBlockData =
  | DallEBlockData
  | ChatGPTBlockData
  | TAnthropicBlockData;

export type DallEBlockData = {
  type: TDallEBlockType;
  prompt: string;
  optimizations: DallEOptimizationsType;
} & BlockDataBase;

export type ChatGPTBlockData = {
  type: TChatGPTBlockType;
  prompt: string;
  optimizations: OptimizationsType;
} & BlockDataBase;
export type TAnthropicBlockData = {
  type: TAnthropicBlockType;
  prompt: string;
  anthropicOptimizations: TAnthropicOptimizationsType;
} & BlockDataBase;

export type TDeepgramBlockType = "DeepgramTranscribe";
export type TScraperBlockType = "WebsiteContentCrawler";

export type DeepgramBlockData = {
  type: TDeepgramBlockType;
  settings: TDeepgramSettings;
} & BlockDataBase;

export type ScraperBlockData = {
  type: TScraperBlockType;
  settings: TScraperSettings;
} & BlockDataBase;

export type SerpBlockData = {
  type: TSerpBlockType;
  settings: TSerpSettings;
} & BlockDataBase;

export type PromptBlockProps = {
  data: PromptBlockData;
  selected: boolean;
  id: string;
};
export type DeepgramBlockProps = {
  data: DeepgramBlockData;
  selected: boolean;
  id: string;
};
export type ScraperBlockProps = {
  data: ScraperBlockData;
  selected: boolean;
  id: string;
};
export type SerpBlockProps = {
  data: SerpBlockData;
  selected: boolean;
  id: string;
};

export type BlockMessageType = {
  severity: "warning" | "error" | "info" | "success";
  message: string;
};

export type TSerpBlockType = "serp";
export type TConstantBlockType = "constant";
export type TLogicBlockType = "logic";
export type TLogic2BlockType = "logic2";
export type TToolWithinToolBlockType = "toolWithinTool";

export type BlockDataBase = { blockMessage?: BlockMessageType; label: string };

export type ConstantBlockData = {
  type: TConstantBlockType;
  constant: string;
} & BlockDataBase;

export type ConstantBlockProps = {
  data: ConstantBlockData;
  selected: boolean;
  id: string;
};

export type ToolWithToolBlockNotSelectedToolData = {
  type: TToolWithinToolBlockType;
  tool: null;
  inputMap: null;
} & BlockDataBase;

export type ToolWithinToolBlockData = {
  type: TToolWithinToolBlockType;
  tool: ExternalToolFE;
  inputMap: MapperObject;
} & BlockDataBase;

export type NotSavedToolWithinToolBlockData =
  | ToolWithinToolBlockData
  | ToolWithToolBlockNotSelectedToolData;

export type ToolWithinToolBlockProps = {
  data: NotSavedToolWithinToolBlockData;
  selected: boolean;
  id: string;
};

export type LogicTypePartial = "is" | "contains" | "checked";

export type LogicType = LogicTypePartial | "exists";

export type LogicItem = {
  input: string;
  inputLogicType: LogicType;
  logicValue: "true" | "false";
  parameterValue?: string;
};

export type LogicBlockData = {
  type: TLogicBlockType;
  logicArray: LogicItem[];
} & BlockDataBase;

export type LogicBlockProps = {
  data: LogicBlockData;
  selected: boolean;
  id: string;
};

export type ValidateIDResponseFE = {
  status: "success";
};

export type UniqueNameOutput = {
  inputs: ToolOutputOption[];
  blocks: ToolOutputOption[];
};

export type ToolOutputOption = {
  value: string;
  type: CustomToolOutputFieldTypes;
};

export type OriginalToolState = {
  toolName: string;
  toolAbout: string;
  toolOutputFields: CustomToolOutputField[];
  toolInputFields: CustomToolInputField[];
  blocks: TBlock[];
  edges: CustomEdge[];
  availableFields: AvailableFieldsType;
  toolOutputOptions: UniqueNameOutput;
  visibility: VisibilityTypes;
  tag: TTagObject;
  estimatedCreditCost: number;
};

export type ToolState = {
  toolVersions: ToolVersionDict;
  main: TToolVersion | null;
  creator: SanitizedUserType | null;
  toolId: string;
  toolVersionId: string;
  currentState: OriginalToolState;
  originalState: OriginalToolState;
  toolLoading: boolean;
  userInput: UserInputDictType;
  toolVersionResponse: PopulatedToolVersionResponseFE | null;
};

export type ToolReducerState = {
  tempViewport: Viewport | null;
  showTagsDialog: boolean;
  reducerId: string;
  toolOutput: UserInputDictType | null;
  toolPercentCompleted: number | null;
} & ToolState;

export type UpdateSType =
  | string
  | number
  | boolean
  | MapperObject
  | TWordPairsDict
  | LogicItem[]
  | TUserKeyWords
  | ExternalToolFE
  | null
  | undefined;

export type TUserKeyWords = string[];
export type TWordPairsDict = { [key: string]: string };
export type UpdateArrayItem = {
  updateValue: UpdateSType;
  dataProperty: string;
  nestedArrayIndex?: number;
  nestedArrayProp?: string;
};

export interface UpdateToolParams {
  main?: string;
  visibility?: VisibilityTypes;
  about?: string;
  name?: string;
  tag?: TTagObject;
}

export type InnerBlockTypes = Extract<
  BlockDataTypes,
  { type: unknown }
>["type"];

export type TBlockTagsType =
  | "promptBlockNode"
  | "deepgramBlockNode"
  | "scraperBlockNode"
  | "serpBlockNode";

export type TBlocksTagsKeys =
  | "ChatGPT"
  | "Anthropic"
  | "WebsiteContentCrawler"
  | "DeepgramTranscribe"
  | "Dall-E2"
  | "Dall-E3"
  | "serp";

export type TBlocksTagsLabels =
  | "OpenAI"
  | "Deepgram"
  | "Web Scraper"
  | "Dall-E"
  | "Anthropic"
  | "Serp";

export enum Block {
  textGeneration = "textGeneration",
  embedded = "embedded",
  logic = "logic",
  constant = "constant",
  speechToText = "speechToText",
  scraper = "scraper",
  serp = "serp",
  imageGeneration = "imageGeneration"
}

export enum BlockTypes {
  PromptBlockNode = "promptBlockNode",
  ToolWithinToolBlockNode = "toolWithinToolBlockNode",
  LogicBlockNode = "logicBlockNode",
  ConstantBlockNode = "constantBlockNode",
  DeepgramBlockNode = "deepgramBlockNode",
  ScraperBlockNode = "scraperBlockNode",
  SerpBlockNode = "serpBlockNode"
}
export type AllBlockTypes =
  | BlockTypes
  | "parentBlockNode"
  | "inputBlockNode"
  | "outputBlockNode";

export interface FormState {
  [id: string]: string | boolean | string[] | LeanFileDocument | undefined;
}

// Define the base type
export type UserInputBaseResponse =
  | string
  | DallEOutputType
  | JSON
  | boolean
  | UserInputDictType
  | LeanFileDocument
  | TUserKeyWords
  | TAnthropicOutputType;

// Define UserInputResponseWithHandledError by adding HandledChatStackError to the base type
export type UserInputResponseWithHandledError =
  | UserInputBaseResponse
  | LogicGate
  | HandledChatStackError;

export type UserInputDictType = {
  [key: string]: UserInputResponseWithHandledError;
};

export type RowUserInputDictType = UserInputDictType & {
  componentId: string;
  rowLoading: boolean;
  columnIdSelected: string;
};

export type LogicGate = {
  type: "logic";
  passed: boolean;
};

export type OpenAITextModels = Tgpt35Turbo | Tgpt4 | Tgpt4TurboPreview | Tgpt4o;
export type TAnthropicModels = TClaude3Opus | TClaude3Sonet;

export type DeprecatedOpenAITextModels = Tgpt35Turbo16K;

export type Tgpt35Turbo = "gpt-3.5-turbo";
export type Tgpt35Turbo16K = "gpt-3.5-turbo-16k";
export type Tgpt4 = "gpt-4";
export type Tgpt4TurboPreview = "gpt-4-turbo-preview";
export type Tgpt4o = "gpt-4o";

export type TClaude3Opus = "claude-3-opus-20240229";
export type TClaude3Sonet = "claude-3-sonnet-20240229";

export type OpenAIImageModels = DallE2Model | DallE3Model;
export type DallE2Model = "dall-e-2";
export type DallE3Model = "dall-e-3";

export type OpenAIModels = OpenAITextModels | OpenAIImageModels;

export type OptimizationsType = {
  llmModel: OpenAITextModels;
  temperature: number;
};
export type TAnthropicOptimizationsType = {
  llmModel: TAnthropicModels;
  temperature: number;
};
export type RequestContextKey = keyof IRequestContextTypes;
export interface IRequestContextTypes {
  [key: `executingBlocks-${string}`]: Set<string>;
  [key: `executedBlocks-${string}`]: Set<string>;
  [key: `intermediateResults-${string}`]: UserInputDictType;
}
export type IRequestContext = Partial<IRequestContextTypes>;

export interface IRunBlockTrackingMetrics {
  toolId: string;
  toolVersionId: string;
  blockId: string;
}

// we mock run block output when running runBlock because
// we don't want to filter them away. We want to test tools
// that might not be in the output
type RunBlockOutput = { name: string; id: string }[];

export type TOutputsForWebsocket = CustomToolOutputField[] | RunBlockOutput;

export type RunPrompt = OptimizationsType & {
  prompt: string;
  outputId: string;
  userInput?: UserInputDictType;
  userId: string;
  clientId?: string;
  componentId: string;
};

export type RunPromptResponse = {
  text: string;
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
  completion: MessageType | undefined;
  explanation: string;
  outputData: string;
};

export type MessageType = {
  role: "user" | "assistant" | "system";
  content: string;
};

export type PostProcessDictType = {
  emailDict: Record<string, number>;
  phoneDict: Record<string, number>;
};

export type MatchParamsType = {
  userId: mongoose.Types.ObjectId;
  isDeleted: false;
  visibility?: VisibilityTypes;
};

export type RunToolAPIBody = { userInput: UserInputDictType };
export type RunToolBody = RunToolAPIBody & { componentId: string };

export type NoBody = null;

export type AnyBody = unknown;

// we have to have an emptyObjectSymbol
// because Record<string, never> doesnt type check correctly because never bypasses typechecking
declare const emptyObjectSymbol: unique symbol;
export type EmptyObject = { [emptyObjectSymbol]?: never };
export type NoParams = EmptyObject;
export type NoQuery = EmptyObject;

export type ComponentIdStreamMap = Map<string, Set<() => void>>;
