import React from "react";
import type { Block } from "@toolflow/shared";
import DraggableBlock from "./DraggableBlock";
import useParentId from "../hooks/useParentId";
import { blockConfig } from "../constants/blockConfig";

function DraggableBlockContainer({ type }: { type: Block }) {
  const { draggableItem: item, icon } = blockConfig[type];
  const parentId = useParentId();
  const onDragStart: React.DragEventHandler<HTMLDivElement> = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify({ ...item, parentNode: parentId })
    );
    event.dataTransfer.effectAllowed = "move";
  };

  return <DraggableBlock onDragStart={onDragStart} icon={icon} />;
}
export default DraggableBlockContainer;
