import { Button, Divider } from "@mui/material";
import { PreviewIcon } from "../../../../../../globalTheme/icons/icons";
import { setDrawerExpandedOpen } from "../../store/toolBuilderSlice";
import { useDispatch } from "react-redux";
import useBuilderDrawer from "../hooks/useBuilderDrawer";

function TestAndPreviewButton() {
  const { expanded } = useBuilderDrawer();
  const dispatch = useDispatch();
  const openPreview = () => {
    dispatch(setDrawerExpandedOpen());
  };
  return (
    <Button
      startIcon={<PreviewIcon />}
      variant="text"
      size="small"
      className="bg-color-white"
      onClick={openPreview}
      disableRipple
      disabled={expanded}
    >
      Test and preview
    </Button>
  );
}

function DrawerTestAndPreview() {
  return (
    <>
      <div className="flex justify-flex-end p-v-8px p-h-24px">
        <TestAndPreviewButton />
      </div>
      <Divider />
    </>
  );
}

export default DrawerTestAndPreview;
