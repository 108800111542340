import { IconButton, Popover } from "@mui/material";
import React from "react";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { AddIcon } from "../../../../../../../../globalTheme/icons/icons";
import AddChipPaper from "./AddChipPaper";

import styles from "./addChipButton.module.css";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function AddChipButton({
  handleAddChip,
  className = "asset-button",
  disableInputs
}: {
  handleAddChip: (type: "input" | "snippet", id: string) => void;
  className?: string;
  disableInputs?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClick = (type: "input" | "snippet", id: string) => {
    setAnchorEl(null);
    handleAddChip(type, id);
  };

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={handleClick}
        className={cx(className, "m-l-8px border-radius-6px")}
      >
        <RemixWrapperIconWithTheme
          Icon={AddIcon}
          size={16}
          // providedColor={toolflowTextDisabledColor}
        />
      </IconButton>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            className: cx("h-100-percent w-100-percent", styles["popper-paper"])
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: 404,
          horizontal: 416
        }}
      >
        <AddChipPaper onClickChip={onClick} disableInputs={disableInputs} />
      </Popover>
    </>
  );
}

export default AddChipButton;
