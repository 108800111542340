import type { BlockDataTypesWithDefault } from "@toolflow/shared";
import { blockHeaderIconDict } from "../constants/blockConfig";

const useBlockIcon = (data?: BlockDataTypesWithDefault) => {
  if (!data) return null;
  const { type } = data;
  if (type === "default") {
    return null;
  }

  return blockHeaderIconDict[type] as React.ElementType | null;
};

export default useBlockIcon;
