import { TextField } from "@mui/material";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import FileUploadButton from "../../../../../files/fileUpload/FileUploadButton";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../globalTheme/muiUtils/appTheme";
import type { UserInputDictType } from "@toolflow/shared";

const TestDeepgramFields = ({
  fields,
  uI,
  setUI,
  file
}: {
  fields: string[];
  uI: UserInputDictType;
  setUI: React.Dispatch<React.SetStateAction<UserInputDictType>>;
  file: string;
}) => {
  const handleUpdate = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newUI = { ...uI, [field]: e.target.value };
    setUI(newUI);
  };
  return (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      {fields.map((field, idx) => (
        <TextField
          name={field}
          key={idx}
          label={field}
          value={uI[field]}
          onChange={(e) => handleUpdate(field, e)}
          multiline
          fullWidth
          margin="normal"
          maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        />
      ))}
      <FileUploadButton name={file} setFormState={setUI} formState={uI} />
    </AccordionWrapper>
  );
};

export default TestDeepgramFields;
