// workspaceSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../stores/store";

export enum DrawerEnum {
  inputs = "inputs",
  outputs = "outputs",
  node = "node",
  toolInfo = "toolInfo"
}

type TToolInfoDrawer = {
  type: DrawerEnum.toolInfo;
};

type TInputDrawer = {
  type: DrawerEnum.inputs;
  expanded: boolean;
};

type TOutputDrawer = {
  type: DrawerEnum.outputs;
  expanded: boolean;
};

type TNodeDrawer = {
  type: DrawerEnum.node;
  nodeId: string;
  expanded: boolean;
};

type TDrawer =
  | TInputDrawer
  | TOutputDrawer
  | TNodeDrawer
  | TToolInfoDrawer
  | null;

interface IToolbuilderInitialState {
  drawer: TDrawer;
}

const initialState: IToolbuilderInitialState = {
  drawer: null
};

export type TToolbuilderReducerName = "toolbuilder";
export const TOOLBUILDER_REDUCER_NAME: TToolbuilderReducerName = "toolbuilder";

const workspaceSlice = createSlice({
  name: TOOLBUILDER_REDUCER_NAME,
  initialState,
  reducers: {
    setDrawer(state, action: PayloadAction<TDrawer>) {
      state.drawer = action.payload;
    },
    toggleDrawerExpanded(state) {
      if (state.drawer && state.drawer?.type !== DrawerEnum.toolInfo) {
        state.drawer.expanded = !state.drawer.expanded;
      }
    },
    setDrawerExpandedOpen(state) {
      if (state.drawer && state.drawer?.type !== DrawerEnum.toolInfo) {
        state.drawer.expanded = true;
      }
    },
    setDrawerExpandedClosed(state) {
      if (state.drawer && state.drawer?.type !== DrawerEnum.toolInfo) {
        state.drawer.expanded = false;
      }
    }
  }
});

export const selectDrawer = (state: RootState) =>
  state[TOOLBUILDER_REDUCER_NAME].drawer;

export const {
  setDrawer,
  toggleDrawerExpanded,
  setDrawerExpandedClosed,
  setDrawerExpandedOpen
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
