import React from "react";
import { addChipTabs } from "../helpers/constants";
import { useFilteredInputsForAutocompleteBase } from "../../../../../../../../../utilities/components/textFields/workspace/hooks/useFilteredInputsForAutocomplete";
import useAutocompleteSnippets from "../../../../../../../../../utilities/components/textFields/workspace/hooks/useAutocompleteSnippets";

import { matchSorter } from "match-sorter";
import type { ToolAutocomplete } from "@toolflow/shared";

const useAddChip = ({ disableInputs }: { disableInputs?: boolean }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [filteredString, setFilteredString] = React.useState("");

  const handleChangeTabIndex = (
    e: React.SyntheticEvent,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  const inputs = useFilteredInputsForAutocompleteBase({ disableInputs });
  const snippets = useAutocompleteSnippets();

  const filteredInputs = matchSorter(inputs, filteredString, {
    keys: ["primary"]
  });
  const autocompleteSnippets = matchSorter(snippets, filteredString, {
    keys: ["primary"]
  });

  const getFilteredEntities = (): {
    [key: string]: {
      entities: ToolAutocomplete[];
      name: string;
      type: "input" | "snippet";
    };
  } => {
    const currentTab = addChipTabs[selectedTabIndex];
    return {
      assets: {
        entities:
          currentTab.value === "all" || currentTab.value === "assets"
            ? filteredInputs
            : [],
        name: "assets",
        type: "input"
      },
      snippets: {
        entities:
          currentTab.value === "all" || currentTab.value === "snippets"
            ? autocompleteSnippets
            : [],
        name: "snippets",
        type: "snippet"
      }
    };
  };

  const entitiesObject = getFilteredEntities();

  return {
    selectedTabIndex,
    handleChangeTabIndex,
    tabs: addChipTabs,
    entitiesObject,
    setFilteredString,
    filteredString
  };
};

export default useAddChip;
