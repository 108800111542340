import { Button } from "@mui/material";
import ButtonLinkToBilling from "./ButtonLinkToBilling";
import { ReactNode } from "react";

const TestToolRun = ({
  action,
  endIcon,
  text,
  showUpgrade,
  disabled
}: {
  action: () => void;
  endIcon?: ReactNode;
  text: string;
  showUpgrade?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="flex align-i-center">
      {showUpgrade && <ButtonLinkToBilling />}
      <Button
        onClick={action}
        variant="contained"
        size="small"
        endIcon={endIcon}
        disableRipple
        disabled={disabled}
      >
        {text}
      </Button>
    </div>
  );
};

export default TestToolRun;
