import {
  type ScraperBlockProps,
  BlockTypes,
  type ScraperBlockData
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import TestToolRun from "../../common/TestToolRun";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestScraperFields from "./TestScraperFields";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";

function TestScraper({ data, id }: Omit<ScraperBlockProps, "selected">) {
  const { settings } = data;
  const {
    action,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    userInput,
    setUserInput
  } = useTest<ScraperBlockData>({
    data,
    id,
    customField: {
      name: data.settings.urlFieldInputKey,
      id: uuidv4(),
      type: TEXTFIELD
    },
    blockType: BlockTypes.ScraperBlockNode,
    fieldInput: ""
  });

  return (
    <div className="flex flex-column w-100-percent h-100-percent">
      <div className="flex-grow-1">
        <TestScraperFields
          id={id}
          uI={userInput}
          setUI={setUserInput}
          urlFieldInputKey={settings.urlFieldInputKey}
        />
        <TestBlockOutput output={output} label={label} />
      </div>
      <DrawerBottom
        rightSlot={
          <TestToolRun
            action={action}
            text={text}
            endIcon={endIcon}
            showUpgrade={showUpgrade}
            disabled={!settings.urlFieldInputKey}
          />
        }
      />
    </div>
  );
}

export default TestScraper;
