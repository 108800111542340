import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useGetDrawerNodeId from "./useGetDrawerNodeId";

const useGetCurrentBlock = () => {
  const nodeId = useGetDrawerNodeId();
  const { state } = useToolbuilderContext();
  const { blocks } = state.currentState;
  return blocks.filter((block) => block.id === nodeId)[0];
};

export default useGetCurrentBlock;
